<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Ürün Arama</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-form-group
        label="Ürün Arama"
        label-for="keyword"
      >
        <b-input-group>
          <b-form-input
            id="keyword"
            v-model="keyword"
            placeholder="Mamül Kodu.."
            @keydown.enter="searchProducts"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="searchProducts"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-card-body>
    <b-table
      v-if="products.length"
      id="product-search"
      :fields="fields"
      :items="products"
      striped
      small
    >
      <template #cell(pcode)="data">
        <div class="d-flex align-items-center">
          <div>
            <b-button
              :id="'popover-target-' + data.item.id"
              variant="flat-warning"
              class="btn-icon mr-1"
              pill
              size="sm"
            >
              <FeatherIcon icon="CameraIcon" />
            </b-button>
            <b-popover
              :target="'popover-target-' + data.item.id"
              triggers="hover"
              placement="lefttop"
            >
              <template #title>
                {{ data.item.pcode }}
              </template>
              <div class="width-250">
                <b-img-lazy
                  :src="baseURL + '/media/products/' + data.item.image"
                  fluid
                />
              </div>
            </b-popover>
          </div>
          <div>
            <span class="font-small-2">
              {{ data.item.pcode }}
              <div class="text-primary">
                {{ data.item.price | toCurrency }} {{ data.item.currency }}
              </div>
            </span>
          </div>
        </div>
      </template>
      <template #cell(id)="data">
        <b-button
          variant="primary"
          pill
          class="btn-icon"
          size="sm"
          :disabled="dataItem.id_products.includes(data.item.id)"
          @click="addProduct(data.item)"
        >
          <FeatherIcon icon="PlusCircleIcon" />
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BImgLazy,
  BInputGroup,
  BInputGroupAppend,
  BPopover,
  BTable,
} from 'bootstrap-vue'

export default {
  name: 'ProductSearch',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BPopover,
    BImgLazy,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      keyword: null,
      fields: [
        {
          key: 'pcode',
          label: 'Mamül Kodu',
        },
        {
          key: 'id',
          label: '',
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    products() {
      return this.$store.getters['products/dataList']
    },
    dataItem() {
      return this.$store.getters['customerPrices/dataItem']
    },
  },
  methods: {
    addProduct(data) {
      const price = data
      if (data.customer_price > 0) {
        price.customer_price = data.customer_price.replace('.', ',')
      }
      this.dataItem.lines.push({
        id: null,
        pcode: price.pcode,
        price: price.customer_price,
        id_products: price.id,
        id_customer_prices: null,
        image: price.image,
      })
      this.dataItem.id_products.push(price.id)
    },
    searchProducts() {
      this.$store.dispatch('products/getDataList', {
        select: [
          'products.id AS id',
          'products.pcode AS pcode',
          'products.price AS price',
          'currencies.symbol AS currency',
          'products.price AS customer_price',
        ],
        or_like: {
          'products.pcode': this.keyword,
        },
      })
    },
  },
}
</script>
