<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Müşteri Fiyat Listesi</b-card-title>
    </b-card-header>
    <b-table
      v-if="dataItem.lines.length"
      :fields="fields"
      :items="dataItem.lines"
      striped
      small
    >
      <template #cell(pcode)="data">
        <div class="d-flex align-items-center">
          <div>
            <b-button
              :id="'price-popover-target-' + data.index"
              variant="flat-warning"
              class="btn-icon mr-1"
              pill
              size="sm"
            >
              <FeatherIcon icon="CameraIcon" />
            </b-button>
            <b-popover
              :target="'price-popover-target-' + data.index"
              triggers="hover"
              placement="lefttop"
            >
              <template #title>
                {{ data.item.pcode }}
              </template>
              <div class="width-250">
                <b-img-lazy
                  :src="baseURL + '/media/products/' + data.item.image"
                  fluid
                />
              </div>
            </b-popover>
          </div>
          <div class="font-small-2">
            {{ data.item.pcode }}
          </div>
        </div>
      </template>
      <template #cell(price)="data">
        <row-price :row-index="data.index" />
      </template>
      <template #cell(id)="data">
        <b-button
          variant="flat-primary"
          pill
          class="btn-icon"
          size="sm"
          @click="removeProduct(data.item.id_products)"
        >
          <FeatherIcon icon="XCircleIcon" />
        </b-button>
      </template>
    </b-table>
    <b-card-body v-else>
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body text-center">
          Fiyat listesi oluşturmak için lütfen ürün ekleyin.
        </div>
      </b-alert>
    </b-card-body>
    <b-card-footer>
      <b-button
        variant="primary"
        @click="submitForm"
      >
        <FeatherIcon icon="CheckCircleIcon" />
        Kaydet
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardFooter,
  BCardBody,
  BAlert,
  BTable,
  BPopover,
  BButton,
  BImgLazy,
} from 'bootstrap-vue'
import RowPrice from '@/views/Admin/Customer_prices/elements/Price.vue'

export default {
  name: 'CustomerPrice',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BAlert,
    BTable,
    BPopover,
    BButton,
    BImgLazy,
    RowPrice,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      options: {
        float: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
      baseURL: this.$store.state.app.baseURL,
      fields: [
        {
          key: 'pcode',
          label: 'Mamül Kodu',
        },
        {
          key: 'price',
          label: 'Fiyat',
        },
        {
          key: 'id',
          label: '',
          tdClass: 'text-right',
          thStyle: { width: '10px' },
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerPrices/dataItem']
    },
  },
  methods: {
    removeProduct(id) {
      const index = this.dataItem.id_products.findIndex(e => e === id)
      const index2 = this.dataItem.lines.findIndex(e => e.id_products === id)
      if (this.dataItem.lines[index].id) {
        this.$store.dispatch('customerPrices/removeLine', this.dataItem.lines[index].id)
          .then(response => {
            if (response) {
              this.dataItem.id_products.splice(index, 1)
              this.dataItem.lines.splice(index2, 1)
            }
          })
      } else {
        this.dataItem.id_products.splice(index, 1)
        this.dataItem.lines.splice(index2, 1)
      }
    },
  },
}
</script>
