<template>
  <b-card
    :title="customer.company"
    sub-title="Fiyat Listesi"
  >
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <end-date />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <currencies />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import EndDate from '@/views/Admin/Customer_prices/elements/EndDate.vue'
import Currencies from '@/views/Admin/Customer_prices/elements/Currencies.vue'

export default {
  name: 'PriceMain',
  components: {
    BCard,
    BRow,
    BCol,
    EndDate,
    Currencies,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/dataItem']
    },
  },
}
</script>
