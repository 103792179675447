<template>
  <validation-provider
    #default="{ errors }"
    name="Fiyat"
    rules="required"
  >
    <cleave
      :id="'price_' + rowIndex"
      v-model="dataItem.lines[rowIndex].price"
      class="form-control form-control-sm"
      :raw="true"
      :options="options.float"
      placeholder="Müşteri Fiyatı"
    />
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Price',
  components: {
    Cleave,
    ValidationProvider,
  },
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      options: {
        float: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerPrices/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
